import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { scroller } from "react-scroll";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {/* about */}
      <Box
        id="about"
        sx={{
          bgcolor: "#0D1039",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box>
          
          <img
            src="./assets/frame.png"
            alt="image"
            style={{
              position: "absolute",
              top: 0,
              width: "50%",
              opacity: "0.6",
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 8 },
          }}
          alignItems="center"
        >
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
           
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2rem 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <Typography color="#fff" variant="h4" fontFamily="Archivo">
                  About Us{" "}
                </Typography>
                <Typography
                  mt={3}
                  color="#fff"
                  fontFamily="Archivo"
                  fontSize="1.3rem"
                  sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                >
                  Unleashing the Spirit of 'AtmaNirbhar Bharat': The Lions' Den
                  is an extraordinary funding gameshow that sets the stage for
                  aspiring entrepreneurs from all corners of India to showcase
                  their visionary ideas and relentless efforts to an exceptional
                  panel of investors. Picture this: a group of 8-10 incredibly
                  successful lions, not only offering their financial support
                  but also their invaluable expertise and precious time, all
                  geared towards propelling these entrepreneurs' businesses
                  towards resounding success. It's a captivating journey where
                  dreams meet opportunity, and together, we fuel the spirit of a
                  Aatmanirbhar Bharat.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
           style={{textAlign:"center"}}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/about.png"
              width="100%"
              height="100%"
              alt="image"
            />
          </Box>
        </Box>
      </Box>



      <Box
       
        id="about"
        sx={{
          bgcolor: "#0D1039",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            fontFamily="Archivo"
            sx={{ color: "#fff", fontWeight: "bold", fontSize: "32px" }}
            data-aos="zoom-out"
            data-aos-duration="1500"
          >
            MEDIA COVERAGE
          </Typography>
           
          <img src="./vector.svg" alt="image" width="20%" />
        </Box>

        <Box
        
          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: { xs: 0, lg: 10 },
            py: { xs: 3, md: 8 },
          }}
          alignItems="center"
        >

          <Box
          style={{textAlign:"center"}}
            sx={{
              display: "block",
              py: { xs: 3, lg: 10 },
              px: { xs: 3, lg: 0 },
            }}
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <img
              src="./assets/article.jpeg"
              width="70%"
              height="70%"
              alt="image"
              style={{border: "13px solid rgb(245, 189, 30)"}}
            />
          </Box>
          <Box flex="1 1 80%" position="relative" height="100%">
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                p: { xs: 4, lg: "0 2rem 0 4rem", xl: "0 2 0 4rem" },
              }}
            >
              <Box
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                 <a style={{textDecoration:"none"}}  href="https://news.abplive.com/brand-wire/the-lions-den-roars-to-life-promising-a-fair-play-in-the-jungle-of-startup-funding-1689755">
                <Typography
                  mt={3}
                  color="#fff"
                  fontFamily="Archivo"
                  fontSize="1.3rem"
                  sx={{ fontSize: { xs: "16px", md: "1.3rem" } }}
                >
                 <b><p style={{margin:"0px",}}>Tired of Failing to Secure Funds, Entrepreneurs Launch Their Own Spot Funding Reality Show Named The Lions' Den</p></b>“After repeatedly getting ghosted by the investor following deal commitment, this brother duo from Gujarat Launch "The Lions' Den" spot Funding Reality Show set to release on JioCinema OTT.” In an exclusive interview with Mid-day, Savan Sanandiya, the Founder of The Lions' Den.....Read More
                </Typography>
                </a>
              </Box>
            </Box>
          </Box>


        </Box>
      </Box>
    </>
  );
};

export default About;
